<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">File Manager</h4>
            </div>
            <div class="card-body">
              <!-- Form to upload a file -->
              <form @submit.prevent="uploadFile">
                <div class="form-group">
                  <label for="file-upload">Upload Image</label>
                  <input
                    type="file"
                    id="file-upload"
                    @change="handleFileChange"
                    class="form-control btn-sm"
                    ref="fileInput"
                  />
                </div>
                <div v-if="isLoadingPreview" class="loading-indicator mt-3">
                  <p>
                    Memuat gambar<span class="dots">{{ loadingDots }}</span>
                  </p>
                </div>

                <div v-else-if="preview" class="preview-container mt-3">
                  <h5>Image Preview:</h5>
                  <img
                    :src="preview"
                    class="img-thumbnail"
                    style="max-width: 300px"
                  />
                </div>
                <!-- <div v-if="preview" class="preview-container">
                  <h5>Image Preview:</h5>
                  <img
                    :src="preview"
                    class="img-thumbnail"
                    style="max-width: 300px"
                  />
                </div> -->
                <!-- Progress Bar -->
                <div class="progress mt-3" v-if="uploadProgress > 0">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="{ width: uploadProgress + '%' }"
                    :aria-valuenow="uploadProgress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ uploadProgress }}%
                  </div>
                </div>
                <button type="submit" class="btn btn-primary btn-sm mt-3">
                  Upload
                </button>
              </form>

              <!-- Search bar for filtering images -->
              <div class="mt-4">
                <input
                  type="text"
                  v-model="searchQuery"
                  placeholder="Search images..."
                  class="form-control"
                />
              </div>

              <!-- Display Categories as Tabs -->
              <ul class="nav nav-tabs mt-4">
                <li
                  class="nav-item"
                  v-for="(category, index) in uniqueCategories"
                  :key="index"
                >
                  <a
                    class="nav-link"
                    :class="{ active: selectedCategory === category }"
                    href="#"
                    @click.prevent="filterByCategory(category)"
                  >
                    {{ toTitleCase(category) }}
                  </a>
                </li>
              </ul>

              <!-- Check if files are loaded and display them -->
              <div v-if="paginatedFiles && paginatedFiles.length" class="mt-4">
                <div class="image-list">
                  <div
                    v-for="(file, index) in paginatedFiles"
                    :key="index"
                    class="image-item position-relative"
                    style="width: 150px; text-align: center"
                  >
                    <img
                      :src="file.path"
                      :alt="file.name"
                      class="img-thumbnail"
                    />
                    <span class="badge badge-primary mt-3">
                      {{ toTitleCase(extractPathLabel(file.path)) }}</span
                    >
                    <p class="image-name">{{ file.name }}</p>

                    <div
                      class="d-flex justify-content-around align-items-center mt-2"
                    >
                      <button
                        @click="copyToClipboard(file.path)"
                        class="btn btn btn-outline-warning btn-sm"
                      >
                        Copy URL
                      </button>
                      <i
                        v-if="file.path.includes('/uploads/')"
                        class="fas fa-trash text-danger"
                        style="cursor: pointer; font-size: 1.2rem"
                        @click="deleteImage(file.name)"
                      ></i>

                      <!-- <i
                        class="fas fa-trash text-danger"
                        style="cursor: pointer; font-size: 1.2rem"
                        @click="deleteImage(file.name)"
                      ></i> -->
                    </div>
                  </div>
                </div>

                <!-- Pagination Controls -->
                <nav class="mt-4">
                  <ul class="pagination">
                    <li
                      class="page-item"
                      :class="{ disabled: pageGroupStart === 1 }"
                    >
                      <a
                        class="page-link"
                        style="cursor: pointer"
                        @click.prevent="prevPageGroup"
                        >Previous</a
                      >
                    </li>
                    <li
                      v-for="page in visiblePages"
                      :key="page"
                      class="page-item"
                      :class="{ active: page === currentPage }"
                    >
                      <a class="page-link" @click.prevent="changePage(page)">{{
                        page
                      }}</a>
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: pageGroupEnd >= totalPages }"
                    >
                      <a
                        class="page-link"
                        style="cursor: pointer"
                        @click.prevent="nextPageGroup"
                        >Next</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
              <p v-else>Loading files...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted, watch, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";
import imageCompression from "browser-image-compression";

export default {
  name: "FileManager",
  setup() {
    const files = ref([]); // Files list
    const selectedFile = ref(null);
    const preview = ref(null);
    const searchQuery = ref("");
    const currentPage = ref(1);
    const itemsPerPage = 12;
    const fileInput = ref(null);
    const uploadProgress = ref(0);
    const selectedCategory = ref("All");
    const loadingDots = ref(""); // Variabel untuk animasi titik
    let dotsInterval = null; // Interval animasi

    const pageGroupStart = ref(1);
    const pageGroupEnd = ref(10);

    const store = useStore();

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        window.location.href = "/";
      });
      return;
    }

    const extractPathLabel = (url) => {
      const parts = url.split("/");
      return parts[parts.length - 2] || "unknown"; // Get the folder name as the label
    };

    // Fungsi animasi titik
    const startLoadingDots = () => {
      let dots = "";
      dotsInterval = setInterval(() => {
        dots = dots.length < 3 ? dots + "." : "";
        loadingDots.value = dots;
      }, 500); // Perubahan titik setiap 500ms
    };

    const stopLoadingDots = () => {
      clearInterval(dotsInterval);
      loadingDots.value = "";
    };

    // const handleFileChange = (e) => {
    //   const file = e.target.files[0];
    //   if (file) {
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       preview.value = e.target.result;
    //     };
    //     reader.readAsDataURL(file);

    //     const now = new Date();
    //     const formattedDate = `${now.getFullYear()}-${String(
    //       now.getMonth() + 1
    //     ).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}`;
    //     const originalName = file.name.substring(0, file.name.lastIndexOf("."));
    //     const extension = file.name.split(".").pop();

    //     const uniqueId = Date.now();
    //     const customFileName = `${formattedDate}_${originalName}_${uniqueId}.${extension}`;

    //     selectedFile.value = new File([file], customFileName, {
    //       type: file.type,
    //     });
    //   }
    // };

    const isLoadingPreview = ref(false);

    const handleFileChange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        isLoadingPreview.value = true;
        startLoadingDots(); // Mulai animasi titik
        try {
          // Konfigurasi kompresi
          const options = {
            maxSizeMB: 0.4,
            maxIteration: 3, // Hanya satu iterasi untuk mempertahankan kualitas 100%
            initialQuality: 0.85,
            useWebWorker: true,
            preserveExif: false,
          };

          // Kompres gambar
          const compressedFile = await imageCompression(file, options);

          // Tampilkan preview gambar yang sudah dikompres
          const reader = new FileReader();
          reader.onload = (e) => {
            preview.value = e.target.result;
            isLoadingPreview.value = false;
            stopLoadingDots(); // Hentikan animasi titik
          };
          reader.readAsDataURL(compressedFile);

          // Siapkan file yang sudah dikompres untuk diunggah
          const now = new Date();
          const formattedDate = `${now.getFullYear()}-${String(
            now.getMonth() + 1
          ).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}`;
          const originalName = file.name.substring(
            0,
            file.name.lastIndexOf(".")
          );
          const extension = file.name.split(".").pop();

          const uniqueId = Date.now();
          const customFileName = `${formattedDate}_${originalName}_${uniqueId}.${extension}`;

          selectedFile.value = new File([compressedFile], customFileName, {
            type: compressedFile.type,
          });
        } catch (error) {
          isLoadingPreview.value = false;
          stopLoadingDots();
          Swal.fire(
            "Error",
            `Failed to compress image: ${error.message}`,
            "error"
          );
        }
      }
    };

    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/images-all", {
          headers: { Authorization: `Bearer ${store.getters.token}` },
        });

        if (response.status === 200) {
          files.value = response.data.images
            .map((url) => ({
              name: url.split("/").pop(),
              path: url,
              type: "file",
            }))
            .sort((a, b) => b.name.localeCompare(a.name));
        }
      } catch (error) {
        Swal.fire("Error", error.message, "error");
      }
    };

    // const uploadFile = async () => {
    //   if (!selectedFile.value) {
    //     Swal.fire({
    //       title: "Error",
    //       text: "Please select a file to upload",
    //       icon: "error",
    //       confirmButtonText: "OK",
    //     });
    //     return;
    //   }

    //   const formData = new FormData();
    //   formData.append("image", selectedFile.value);

    //   try {
    //     const response = await axiosInstance.post("/images-uploads", formData, {
    //       headers: {
    //         Authorization: `Bearer ${store.getters.token}`,
    //         "Content-Type": "multipart/form-data",
    //       },
    //       onUploadProgress: (progressEvent) => {
    //         uploadProgress.value = Math.round(
    //           (progressEvent.loaded * 100) / progressEvent.total
    //         );
    //       },
    //     });

    //     if (response.status === 200) {
    //       Swal.fire({
    //         title: "Success",
    //         text: "File uploaded successfully",
    //         icon: "success",
    //         confirmButtonText: "OK",
    //       });
    //       fetchData();
    //       selectedFile.value = null;
    //       preview.value = null;
    //       fileInput.value.value = "";
    //       uploadProgress.value = 0;
    //       window.location.reload();
    //     } else {
    //       Swal.fire({
    //         title: "Error",
    //         text: `Error: ${response.data.message}`,
    //         icon: "error",
    //         confirmButtonText: "OK",
    //       });
    //     }
    //   } catch (error) {
    //     Swal.fire({
    //       title: "Error",
    //       text: `Error: ${error.message}`,
    //       icon: "error",
    //       confirmButtonText: "OK",
    //     });
    //     // uploadProgress.value = 0;
    //   }
    // };

    const uploadFile = async () => {
      if (!selectedFile.value) {
        Swal.fire({
          title: "Error",
          text: "Please select a file to upload",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      const formData = new FormData();
      formData.append("image", selectedFile.value);

      try {
        const response = await axiosInstance.post("/images-uploads", formData, {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            uploadProgress.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        });

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "File uploaded successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
          fetchData();
          selectedFile.value = null;
          preview.value = null;
          fileInput.value.value = "";
          uploadProgress.value = 0;
          window.location.reload();
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
        uploadProgress.value = 0;
      }
    };
    const copyToClipboard = (url) => {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          Swal.fire({
            title: "Copied!",
            text: "URL has been copied to clipboard.",
            icon: "success",
            confirmButtonText: "OK",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: `Failed to copy URL: ${error.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    };

    const toTitleCase = (str) => {
      return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    };

    const deleteImage = async (fileName) => {
      try {
        const confirmation = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
        });

        if (confirmation.isConfirmed) {
          const response = await axiosInstance.delete(
            `/images-delete/${fileName}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.value}`,
              },
            }
          );

          if (response.status === 200) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            // Optionally, remove the item locally from the files array or refetch the data
            files.value = files.value.filter((file) => file.name !== fileName);
          } else {
            Swal.fire(
              "Error!",
              "There was an issue deleting the file.",
              "error"
            );
          }
        }
      } catch (error) {
        Swal.fire("Error!", `Error: ${error.message}`, "error");
      }
    };

    const filteredFilesByCategory = computed(() => {
      if (selectedCategory.value === "All") {
        return files.value;
      }
      return files.value.filter(
        (file) => extractPathLabel(file.path) === selectedCategory.value
      );
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredFilesByCategory.value.length / itemsPerPage);
    });

    const paginatedFiles = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      return filteredFilesByCategory.value.slice(start, end);
    });

    const changePage = (page) => {
      if (page > 0 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const nextPageGroup = () => {
      if (pageGroupEnd.value < totalPages.value) {
        pageGroupStart.value += 10;
        pageGroupEnd.value += 10;
      }
    };

    const prevPageGroup = () => {
      if (pageGroupStart.value > 1) {
        pageGroupStart.value -= 10;
        pageGroupEnd.value -= 10;
      }
    };
    // Sesuaikan pagination
    const visiblePages = computed(() => {
      const pages = [];
      for (
        let i = pageGroupStart.value;
        i <= pageGroupEnd.value && i <= totalPages.value;
        i++
      ) {
        pages.push(i);
      }
      return pages;
    });

    const uniqueCategories = computed(() => {
      const categories = files.value.map((file) => extractPathLabel(file.path));
      return ["All", ...new Set(categories)];
    });

    const filterByCategory = (category) => {
      selectedCategory.value = category;
      currentPage.value = 1; // Reset to page 1 when category changes
    };

    onBeforeUnmount(() => {
      stopLoadingDots();
    });

    watch(filteredFilesByCategory, () => {
      currentPage.value = 1;
    });

    onMounted(fetchData);

    return {
      files,
      handleFileChange,
      uploadFile,
      preview,
      searchQuery,
      currentPage,
      paginatedFiles,
      totalPages,
      changePage,
      fileInput,
      copyToClipboard,
      uploadProgress,
      extractPathLabel,
      deleteImage,
      selectedCategory,
      uniqueCategories,
      filteredFilesByCategory,
      filterByCategory,
      toTitleCase,
      isLoadingPreview,
      loadingDots,
      nextPageGroup,
      prevPageGroup,
      visiblePages,
      pageGroupStart,
      pageGroupEnd,
    };
  },
};
</script>

<style scoped>
.nav-tabs {
  justify-content: center;
}
.nav-tabs .nav-item .nav-link {
  cursor: pointer;
}

.nav-tabs .nav-link.active {
  background-color: #007bff;
  color: white;
}

.content-body {
  /* padding: 30px; */
  background: #f4f4f9;
}

.preview-container {
  margin-top: 20px;
}

.image-list {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.image-item {
  width: 150px;
  text-align: center;
}

.img-thumbnail {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.image-name {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.pagination {
  justify-content: center;
}

.page-item.disabled .page-link {
  pointer-events: none;
  color: #6c757d;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #007bff;
}

.dots {
  display: inline-block;
  width: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
