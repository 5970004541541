<template>
  <div
    class="modal fade"
    :class="{ show: showModal }"
    tabindex="-1"
    :style="{ display: showModal ? 'block' : 'none' }"
  >
    <div
      class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Detail Data Pelamar</h5>
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 col-md-6">
              <h6 class="text-primary mb-3">Informasi Personal</h6>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Nama Lengkap</div>
                <div class="col-7">
                  {{ selectedItem[0]?.nama_lengkap || "-" }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 fw-bold">No Telepon</div>
                <div class="col-7">
                  {{ selectedItem[0]?.no_telepon || "-" }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Email</div>
                <div class="col-7 text-wrap">
                  {{ selectedItem[0]?.email || "-" }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Tempat Lahir</div>
                <div class="col-7">
                  {{ selectedItem[0]?.tempat_lahir || "-" }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Tanggal Lahir</div>
                <div class="col-7">
                  {{ selectedItem[0]?.tanggal_lahir || "-" }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Jenis Kelamin</div>
                <div class="col-7">
                  {{ selectedItem[0]?.jenis_kelamin || "-" }}
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <h6 class="text-primary mb-3">Informasi Tambahan</h6>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Status Perkawinan</div>
                <div class="col-7">
                  {{ selectedItem[0]?.status_perkawinan || "-" }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Agama</div>
                <div class="col-7">{{ selectedItem[0]?.agama || "-" }}</div>
              </div>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Pendidikan</div>
                <div class="col-7">
                  {{ selectedItem[0]?.pendidikan || "-" }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Jurusan</div>
                <div class="col-7">{{ selectedItem[0]?.jurusan || "-" }}</div>
              </div>
            </div>

            <div class="col-12 col-md-6 mt-3">
              <h6 class="text-primary mb-3">Pengalaman Kerja</h6>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Pengalaman Kerja</div>
                <div class="col-7">
                  {{ selectedItem[0]?.pengalaman_kerja || "-" }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Perusahaan Sebelumnya</div>
                <div class="col-7">
                  {{ selectedItem[0]?.perusahaan_before || "-" }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Posisi Terakhir</div>
                <div class="col-7">
                  {{ selectedItem[0]?.posisi_terahir || "-" }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Tanggal Mulai</div>
                <div class="col-7">
                  {{ selectedItem[0]?.tgl_mulai_before || "-" }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Tanggal Selesai</div>
                <div class="col-7">
                  {{ selectedItem[0]?.tgl_selesai_before || "-" }}
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 mt-3">
              <h6 class="text-primary mb-3">Informasi Lamaran</h6>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Sumber Informasi</div>
                <div class="col-7">
                  {{ selectedItem[0]?.sumber_informasi || "-" }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 fw-bold">Posisi Lamar</div>
                <div class="col-7">
                  {{ selectedItem[0]?.name_division || "-" }}
                </div>
              </div>
            </div>

            <div class="col-12 mt-3">
              <h6 class="text-primary mb-3">Deskripsi Pengalaman</h6>
              <p>{{ selectedItem[0]?.deskripsi_pengalaman_kerja || "-" }}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="downloadCoverLetter" class="btn btn-primary w-100">
            Download Cover Letter
          </button>
          <button @click="closeModal" class="btn btn-secondary w-100 mt-2">
            Tutup
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultURL from "@/config";

export default {
  props: {
    showModal: Boolean,
    selectedItem: Object,
  },
  methods: {
    closeModal() {
      this.$emit("close"); // Emit close event to parent
    },
    downloadCoverLetter() {
      // Get the file path from the selectedItem
      const filePath = this.selectedItem[0]?.cover_letter;

      if (!filePath) {
        return;
      }

      // Base URL of your API
      const fileUrl = `${defaultURL}/storage/${filePath}`;

      // Open the file in a new tab for download
      window.open(fileUrl, "_blank");
    },
  },
};
</script>

<style scoped>
/* Modal Overlay and Content Styling */
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  max-height: 90vh;
}

.modal-content {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1rem;
}

.modal-body {
  padding: 1.5rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.modal-footer {
  border-top: 1px solid #e9ecef;
  padding: 1rem;
  flex-direction: column;
}

/* Custom Row and Column Styling */
.row > div {
  margin-bottom: 0.5rem;
}

/* Typography */
.fw-bold {
  font-weight: bold;
  color: #1d2329;
}

/* Button Styling */
.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  transition: all 0.3s ease;
}

.btn-secondary:hover {
  background-color: #545b62;
  border-color: #545b62;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .modal-dialog {
    margin: 1.75rem 0.5rem;
    max-height: 95vh;
  }

  .modal-body {
    padding: 1rem;
  }

  .row > div {
    margin-bottom: 1rem;
  }

  .modal-footer .btn {
    margin-bottom: 0.5rem;
  }
}

/* Smooth Transitions */
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
}

/* Prevent Overflow */
.modal-body {
  overflow-y: auto;
  max-height: 60vh;
}

/* Text Ellipsis for Long Content */
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
